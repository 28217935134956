import React, { useState } from 'react';
import './TestimonialCarousel.css';
import img1 from '../components/images/testimonial1.jpeg';
import img2 from '../components/images/testimonial2.jpeg';

function TestimonialCarousel() {
    const testimonials = [
        {
            quote: "Scraps is the future of rethinking our connection with STUFF 🔥",
            author: "Lara Corrêa\n - Co-founder @ NOVA SBE Innovation Ecosystem",
            photo: img2
        },
        {
            quote: "Your commitment to collaboration and innovation is truly inspiring.",
            author: "Fernanda Nazário\n - Communication Designer @ novobanco",
            photo: img1
        }
    ];

    const [current, setCurrent] = useState(0);

    const nextTestimonial = () => {
        setCurrent(current === testimonials.length - 1 ? 0 : current + 1);
    };

    const prevTestimonial = () => {
        setCurrent(current === 0 ? testimonials.length - 1 : current - 1);
    };

    return (
        <div className="testimonial-carousel">
            <button onClick={prevTestimonial} className="arrow left">←</button>
            <div className="testimonial">
                <div className="testimonial-image">
                    <img src={testimonials[current].photo} alt="User" />
                </div>
                <div className="testimonial-text">
                    <p className="quote">
                        "{testimonials[current].quote}"
                    </p>
                    <p className="author">{testimonials[current].author}</p>
                </div>
            </div>
            <button onClick={nextTestimonial} className="arrow right">→</button>
        </div>
    );
}

export default TestimonialCarousel;
