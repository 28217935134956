import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS library
import './EmailForm.css';

const EmailForm = () => {
    const [formData, setFormData] = useState({
        from_name: '',
        subject: '',
        email:'',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.sendForm('service_vjvr89c', 'template_r9mpl99', e.target, '88ZqHBYVhDF_iUNLV')
            .then((result) => {
                console.log(result.text);
                // Clear form fields after successful submission
                setFormData({
                    from_name: '',
                    subject: '',
                    email:'',
                    message: ''
                });
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <form className="email-form" onSubmit={handleSubmit}>
            <input
                type="text"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                placeholder="Your Name"
                required
            />
            <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your E-mail"
                required
            />
            <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject"
                required
            />
            <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your Message"
                required
            ></textarea>
            <button type="submit"><b>CONTACT US</b></button>
        </form>
    );
};

export default EmailForm;
