import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logoImage from './images/logobranco.png';

const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = event => {
            if (!ref.current || ref.current.contains(event.target)) return;
            handler(event);
        };
        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [ref, handler]);
};

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [open, setOpen] = useState(false);
    const node = React.useRef();
    useOnClickOutside(node, () => setOpen(false));

    const handleClick = () => {
        setClick(!click);
        setOpen(!open);
        if (!open) {
            setOpen(true);
        }
    };

    const handleLinkClick = (sectionId) => {
        closeMobileMenu();
        const section = document.getElementById(sectionId);
        if (section) {
            let offset = 100;


            const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };

    const closeMobileMenu = () => {
        setClick(false);
        setOpen(false);
    };

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
            if(click){
                setOpen(true);
            } else {
                setOpen(false);
            }
        } else {
            setButton(true);
            setOpen(false);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <div className={click ? 'menu-icon active' : 'menu-icon'} onClick={handleClick}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {open && <div className='overlay' onClick={closeMobileMenu}></div>}
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={() => handleLinkClick('header')}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={() => handleLinkClick('story-section')}>
                                Story
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={() => handleLinkClick('cta')}>
                                Join Us!
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={() => handleLinkClick('supporting-content-section')}>
                                Testimonials
                            </Link>
                        </li>
                        {/*<li className='nav-item'>*/}
                        {/*    <Link to='/' className='nav-links' onClick={() => handleLinkClick('red-rectangle-section')}>*/}
                        {/*        What, How, Why*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={() => handleLinkClick('email-form-section')}>
                                Contacts!
                            </Link>
                        </li>
                    </ul>
                    <Link to='/' className='navbar-logo' onClick={() => handleLinkClick('header')}>
                        <img src={logoImage} alt='Logo' className='navbar-logo-image' />
                    </Link>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
