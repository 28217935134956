import React, { useEffect, useRef, useState } from 'react';
import logo from './components/images/logovermelho.png';
import TestimonialCarousel from './components/TestimonialCarousel';
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Link } from "react-router-dom";
import EmailForm from './components/EmailForm';
import storyImageSmall from './components/images/storyyy.png';
import storyImage from './components/images/desktop-story.jpg';
import contactImage from './components/images/contactUs.png';
import artistsImage from './components/images/artists2.png';
import titleImage from './components/images/catchytitle.png';
import whatImage from './components/images/What.png';
import howImage from './components/images/How.png';
import whyImage from './components/images/Why.png';
import linkedinIcon from './components/images/linkedin.png';
import instagramIcon from './components/images/instagram.png';
import './App.css';
import leftImage from './components/images/team.png'
import socialsImage from './components/images/socials.png'
import socialsMobile from './components/images/socials_mobile.png'


function App() {
    const logoRef = useRef(null);
    const [currentStoryImage, setCurrentStoryImage] = useState(storyImage);
    const [currentSocialsImage, setCurrentSocialsImage] = useState(socialsImage);
    const rectangleItemRefs = useRef([]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1100) {
                setCurrentStoryImage(storyImageSmall);
                setCurrentSocialsImage(socialsMobile);
            } else {
                setCurrentStoryImage(storyImage);
                setCurrentSocialsImage(socialsImage);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scaleFactor = Math.min(window.scrollY / 500, 1);
            if (logoRef.current) {
                logoRef.current.style.transform = `scale(${1 + scaleFactor})`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const setEqualHeights = () => {
            const heights = rectangleItemRefs.current.map(ref => ref.querySelector('p').clientHeight);
            const maxHeight = Math.max(...heights);

            rectangleItemRefs.current.forEach(ref => {
                ref.querySelector('p').style.height = `${maxHeight}px`;
            });
        };

        setEqualHeights();
        window.addEventListener('resize', setEqualHeights);

        return () => {
            window.removeEventListener('resize', setEqualHeights);
        };
    }, []);

    return (
        <div className="App">
            <Router>
                <Navbar />
            </Router>
            <header className="App-header" id="header">
                <img src={logo} className="App-logo" alt="logo" ref={logoRef} />
                {/*<h1>DESIGN<del style={{ color: 'red' }}>ER</del> CLOTHING</h1>*/}
            </header>

            <section className="story-section" id="story-section">
                {/*<img src={titleImage} alt="Catchy Title" className="title-image" />*/}
                <p className="story-text-main" >
                    <b>COLLECTIVE ACTION</b> IS THE KEY TO TRANSFORMING THE FASHION INDUSTRY.
                </p>
                <div className="story-text-secondary">
                    <span>stylish individuals</span> <span className="plus">+</span> <span>fashion artists</span>
                </div>
                <p className="story-text">
                    Embark on <b style={{ color: '#d8131c'}}>collaborative upcycling projects</b> and come up with unique pieces, tailored to your specific <b>needs</b> and tastes.
                </p>

                <img className="storyImage" src={currentStoryImage} alt="Story" style={{ width: '100%', height: 'auto' }} />

            </section>



            <section id="cta" className="cta-section">
                <div className="artist-image-container">
                    <img src={artistsImage} alt="Catchy Title" className="artist-image" />
                </div>
                <div className="cta-text-container">

                    <h2>Are you an open-minded artist eager to co-create?</h2>
                    <p>
                        <b>Join our exclusive group</b> to conduct experimental projects, test new features of our App and be at the forefront of SCRAPS' movement!
                    </p>

                    <div className="cta-button-container">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScchg8t1EXf9zpoPM11zAPZDXLIg-4ytYzupEWHFQmFbmZQoA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" className="cta-button">
                            <button>Count Me In!</button>
                        </a>
                    </div>
                </div>
            </section>

            <section className="supporting-content-section" id="supporting-content-section">
                <TestimonialCarousel />
            </section>

            <section className="red-rectangle-section" id="red-rectangle-section">
                <div className="rectangle-item" ref={el => rectangleItemRefs.current[2] = el}>
                    <img src={whyImage} alt="Why" className="title-image" />
                    <p>The power of community can revolutionize the fashion industry
                    </p>
                </div>
                <div className="rectangle-item" ref={el => rectangleItemRefs.current[1] = el}>
                    <img src={howImage} alt="How" className="title-image" />
                    <p>Through collaborative design projects</p>

                </div>
                <div className="rectangle-item" ref={el => rectangleItemRefs.current[0] = el}>
                    <img src={whatImage} alt="What" className="title-image" />
                    <p>An app that allows artists to co-create with customers</p>
                </div>


            </section>





            <section className="email-form-section" id="email-form-section">
                {/*<img src={contactImage} alt="Catchy Title" className="contact-image" />*/}
                <div className="form-container">
                    <img src={leftImage} alt="Descriptive Alt Text" className="side-image" />
                    <EmailForm />
                </div>
                <img src={currentSocialsImage} alt="Socials" className="full-width-image" />
            </section>


            <footer className="footer-section">
                <div className="footer-content">
                    <div className="footer-left">
                        {/*<p>Scraps</p>*/}
                    </div>
                    <div className="footer-right">
                        <a href="https://www.linkedin.com/company/scrapsstudios" target="_blank" rel="noopener noreferrer">
                            <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
                        </a>
                        <a href="https://www.instagram.com/scraps.pt/" target="_blank" rel="noopener noreferrer">
                            <img src={instagramIcon} alt="Instagram" className="social-icon" />
                        </a>
                    </div>
                </div>
                <p>&copy; 2024 SCRAPS</p>
            </footer>
        </div>
    );
}

export default App;
